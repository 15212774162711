import React, { useContext } from 'react'
import {
  Container,
  Navbar,
  Image,
  Button,
  Nav,
  NavDropdown,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleUser,
  faCloudArrowUp,
  faFolder,
  faList,
  // faMagnifyingGlass,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { CNLogo_img, CNLogo_text } from '../assets'
import { AuthContext } from './ContextProvider'
import apiServices from '../services/apiServices'

function NavBar() {
  const { auth, setAuth } = useContext(AuthContext)
  const navigate = useNavigate()
  const handleLogout = async () => {
    await apiServices.logout()
    document.cookie = `csrftoken=;sessionid=;`
    localStorage.clear()
    setAuth({
      authed: false,
    })
    navigate('/')
  }

  // const [selected, setselected] = useState('')

  return (
    <Navbar className="bg-chelonia2 p-0">
      <Container fluid className="px-4">
        <Navbar.Brand className="d-flex" href="#home" title="回首頁">
          <Image
            src={CNLogo_img}
            className="m-auto pe-2"
            style={{ height: '2rem' }}
          />
          <Image
            src={CNLogo_text}
            className="m-auto pe-2"
            style={{ height: '2rem' }}
          />
        </Navbar.Brand>
        <Nav
          className="ms-auto pe-5"
          // style={{ borderRight: '1px solid #fff' }}
        >
          {auth.isAdmin ? (
            <Nav.Link className="text-light ms-2" href="/UsersManager">
              <FontAwesomeIcon icon={faUsers} />
              &ensp;後台管理
            </Nav.Link>
          ) : (
            <>
              <Nav.Link className="text-light" href="/allvideo">
                <FontAwesomeIcon icon={faFolder} />
                &ensp;專案管理
              </Nav.Link>
              <Nav.Link className="text-light mx-2" href="/MaterialManagement">
                <FontAwesomeIcon icon={faCloudArrowUp} />
                &ensp;素材管理
              </Nav.Link>
              <Nav.Link className="text-light ms-2" href="/UsersManager">
                <FontAwesomeIcon icon={faUsers} />
                &ensp;後台管理
              </Nav.Link>
              <div
                title="選擇部門 （此功能施工中 ⛏︎）"
                className="ps-3"
                style={{ cursor: 'pointer' }}
              >
                <NavDropdown
                  placeholder="請選擇部門..."
                  title={
                    <span>
                      ｜&emsp;
                      <FontAwesomeIcon icon={faList} />
                      &ensp;
                      {auth.orgs && auth.orgs[auth.selectedOrg || 0]
                        ? auth.orgs[auth.selectedOrg || 0].name
                        : '組織'}
                    </span>
                  }
                  id="nav-dropdown"
                  style={{ userSelect: 'none' }}
                >
                  {(auth.orgs || []).map((item, index) => (
                    <NavDropdown.Item
                      key={index}
                      eventKey="4.1"
                      onClick={() =>
                        setAuth({
                          ...auth,
                          selectedOrg: index,
                        })
                      }
                    >
                      {item.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </div>
            </>
          )}
        </Nav>
        <div className="d-flex my-auto mx-4 text-light">
          <FontAwesomeIcon className="h3 mb-0" icon={faCircleUser} />
          &ensp;
          <h6 className="my-auto fw-bold">{auth.username}</h6>
        </div>
        <Button
          className="fw-bolder my-auto me-2"
          style={{ cursor: 'pointer' }}
          size="sm"
          variant="outline-light"
          title="登出"
          onClick={handleLogout}
        >
          登 出
        </Button>
      </Container>
    </Navbar>
  )
}

export default NavBar
